import React from "react";
import "./helpandsupport.css";
import { Container } from "react-bootstrap";
import NavBar from "../navbar/navbar";
import Footer from "../footer/footer";

function PrivacyPolicy() {
  return (
    <>
    <NavBar />

    <section id="privacy">
      <Container className="privacy-policy-container">
        <div className="main-heading privacy-head">Privacy Policy</div>

        <p>
          Your privacy matters to Moblio Future Technologies Private Limited
          (the “Company”, “we”, “Onescribe”, “us” or “our”).
        </p>

        <p>
          This Privacy Policy (“Policy”) describes the policies and procedures
          on the collection, use, processing, storage, retrieval, disclosure,
          transfer and protection of your information, including personal
          information and sensitive personal data or information
          (“Information”), that Onescribe may receive through Your online
          access, interaction or use, of the Onescribe mobile applications
          (“Onescribe App”) or Our website www.onescribe.app (collectively
          referred to as the “Platform”) or through Your offline interaction
          with Us including through mails, phones, in person, etc., or while
          availing Our Offerings. The terms “You”, “Your”, “User” refer to
          persons who use the Platform. The term “Partner” refers to individuals
          or business owners who provide Offerings. And term Offerings refers to
          products or services.
        </p>
        <p>
          Please read the Policy carefully before using or registering on the
          Platform or accessing any material or availing any Offerings through
          the Platform. By registering You directly accept the Policy and agree
          to be legally bound by the same. If You do not agree to the Policy,
          please do not use or access the Platform. We request You to read this
          Policy from time to time to keep yourself updated with the changes
          that We make to this Policy.{" "}
        </p>

        <div className="collect-information">
          <h2>How do We collect Information?</h2>

          <ul>
            <li>directly from You when You provide it to Us; and/or</li>
            <li>automatically as you navigate through our Platform</li>
            <li>third parties or publicly available sources</li>
          </ul>

          <h2>Information You provide Us</h2>

          <ol>
            <li>
              Account Information: As part of registration process We collect
              mobile phone numbers, email, postal/ home delivery address
            </li>
            <li>
              Transactional Information: If You subscribe to the Offerings, We
              may collect and store information about Your transactions,
              including (but not limited to) your phone number, address, email,
              billing information and credit or payment card information.
            </li>
            <li>
              Your content: Information You provide through Platform, including
              Your reviews, comments and feedbacks
            </li>
          </ol>

          <p>
            We only collect personal information that is reasonably necessary to
            provide Our Offerings. If You refuse to provide Us with Information
            We may not be able to perform the contract (for example, to provide
            You with the Offerings). In this case, We may have to cancel or
            limit Your access to the Platform. We shall not retain sensitive
            personal Information for longer than is required for the purposes
            for which the information may lawfully be used.
          </p>
        </div>

        <div className="automatically-collect">
          <h2>Information We automatically collect:</h2>

          <ol>
            <li>
              Computer and Device Information: Information about Your computer,
              Internet connection and mobile device, including your IP address,
              operating systems, browser type, other browsing information
              (connection, speed, connection type etc.), device type, device ID,
              mobile network information and the device's telephone number. We
              may collect data from SMS/ text messages upon receiving device
              access permissions from You for the purposes of (i) issuing and
              receiving one-time passwords (OTP) and other device verification.
            </li>
            <li>
              Location Information: When You use Our Platform we may collect and
              process information about Your mobile device's GPS location
              (including the latitude, longitude or altitude of your mobile
              device)
            </li>
            <li>
              Your Activity on Platform: Information about Your activity such as
              search queries, pages viewed, how long You visited Our Platform,
              the date and time You used the Platform, Ad data and any other
              similar Information.
            </li>
            <li>
              Usage Information: Details of Your use of Our Platform including,
              traffic data, logs, and other communication data and resources
              that You access or use on Our Platform.
            </li>
          </ol>
        </div>

        <div className="third-parties">
          <h2>Information from Third Parties</h2>

          <p>
            We may receive Information about You from third parties, such as
            other users, Partners or Our affiliated companies or if You use any
            of the other websites/apps We operate or the other services we
            provide. We may also get data from any publicly available source
            etc.
          </p>
        </div>
        <div className="collected-information">
          <h2>How We use the Information We collect:</h2>

          <p>
            We may use, store and process the information provided by You to:
          </p>

          <ul>
            <li>to enable you to access the Onescribe Platform</li>
            <li>to send You SMS to verify Your account registration</li>
            <li>
              to provide relevant offers or rewards to You, based on Your
              subscribing patterns
            </li>
            <li>to track transactions and process payments</li>
            <li>
              to contact You with regard to Your use of the Offerings from Us/
              Our Partners
            </li>
            <li>for any contests and sweepstakes</li>
            <li>
              process and respond to Your queries, comments, reviews and
              questions
            </li>
            <li>
              understand Our users (what they do on Our Platfrom, what features
              they like, how they use them, etc.), improve the content and
              features of Our Platform (such as by personalizing content to Your
              interests), process and complete Your transactions, and make
              special offers
            </li>
            <li>enable Us to show You ads that are relevant to You</li>
            <li>
              to communicate important notices and changes to Our Offerings
            </li>
            <li>
              to enforce our terms and conditions and this Policy, and resolve
              any disputes
            </li>
            <li>
              to carry out Our obligations and enforce Our rights arising from
              any contracts entered into between You and Us
            </li>
            <li>provide You with customer support</li>
            <li>for any other purpose with Your consent</li>
          </ul>

          <p>
            We may use Information collected from You to display advertisements
            to Our advertisers'/Partners but We do not disclose Your Information
            for these purposes without Your consent, if You click on or
            otherwise interact with an advertisement, the advertiser may assume
            that You meet its target criteria.
          </p>
          <p>
            You agree and acknowledge that We may share data without Your
            consent, when it is required by law or by any court or government
            agency or authority to disclose such information. Such disclosures
            are made in good faith and belief that it is reasonably necessary to
            do so for enforcing this Policy or the Terms, or in order to comply
            with any applicable laws and regulations.
          </p>
        </div>
        <div className="sharing-disclosure">
          <h2>Sharing and Disclosure of data with/ for</h2>

          <p>
            We may disclose Information that We collect or You provide, as
            described in this Policy, in the following ways:
          </p>
          <ol>
            <li>to Partners to enable them to provide You with Offerings</li>
            <li>
              to third party payment gateway provider (Razorpay) may be required
              to collect certain financial information from You including, but
              not restricted to, Your credit/debit card number or Your bank
              account details. All financial Information collected from You by
              such third party payment gateway providers will be used only for
              billing and payment processes
            </li>
            <li>
              to Our subsidiaries and affiliates, which are entities under
              common ownership or control of Our ultimate parent company Moblio
              Future Technologies Private Limited
            </li>
            <li>
              to market study and other benefits such as to conduct data
              analysis for improving Offerings
            </li>
            <li>
              We may disclose Your information when such disclosure is
              requisitioned under any law or judicial or when We, in our sole
              discretion, deem it necessary in order to protect Our rights or
              the rights of other users, to prevent harm to persons or property,
              to fight fraud and credit risk
            </li>
            <li>
              to a buyer or other successor in the event of a merger,
              divestiture, restructuring, reorganization, dissolution or other
              sale or transfer of some or all of Onescribe assets
            </li>
          </ol>
        </div>
        <div className="safety-security">
          <h2>Safety and Security of Your Information:</h2>

          <p>
            We are committed to protecting Your Information as if it is Our own.
            We will protect it within commercially acceptable means to prevent
            loss and theft, as well as unauthorized access, disclosure, copying,
            use, or modification. Your Information is contained within secured
            networks and is only accessible by a limited number of authorized
            persons who have access rights to such systems or otherwise require
            such Information for the purposes provided in this Policy. These
            authorized persons are also under an obligation to keep such
            Information confidential.
          </p>
          <p>
            Although We make best possible efforts to transmit and store all the
            Information provided by you in a secure operating environment that
            is not open to public, You understand and acknowledge that there is
            no such thing as complete security and We do not guarantee that
            there will be no unintended disclosures of any information and
            potential security breaches. You agree not to hold Us responsible
            for any breach of security or for any action of any third parties
            that receive Your personal Information or events that are beyond our
            reasonable control including, acts of government, computer hacking,
            unauthorized access to computer data and storage device, computer
            crashes, breach of security, encryption and any unforeseen
            activities, etc.
          </p>
          <p>
            If You know or have reason to believe that Your account has been
            compromised or suspect unauthorized use You are required to contact
            Us immediately or email Us to info@onescribe.app
          </p>
        </div>
        <div className="transfer-information">
          <h2>Transfer of Your Information</h2>

          <p>
            We comply with applicable laws in respect of storage and transfers
            of personal data. As a part of Your use of the Offerings, the
            Information and personal data You provide to Us may be transferred
            to and stored in countries other than the country You are based in.
            This may happen if any of Our servers are from time to time located
            in a country other than the one You are based, or one of Our
            Partners or service providers is located in a country other than one
            You are based in. By submitting Your Information and personal data
            to Us, You agree to the transfer, storage, and processing of such
            Information and personal data in the manner described above.
          </p>
        </div>
        <div className="cookies-policy">
          <h2>Cookies Policy </h2>

          <p>
            We use data collection devices such as “cookies” etc. on certain
            parts of the app to help analyse the Offerings, User interaction
            with the app, measure promotional effectiveness, and promote trust
            and safety. For the sake of clarity, “cookies” are small piece of
            data that Our website stores on Your computer, and accesses each
            time You visit, so We can understand how You use Our site. This
            helps Us serve You content based on preferences You have specified.
            It also help Us to obtain information that assists Us in curating
            the services more targeted to Your interests.
          </p>
        </div>
        <div className="childrens-policy">
          <h2>Children’s Policy:</h2>

          <p>
            Our Platform do not aim any of Our Offerings directly at children
            under the age of 18, and We do not knowingly collect personal
            information about children under 18. If We later learn that We have
            collected personal information from child under age 18 without
            verification of parental consent, We will delete that information as
            quickly as possible. If You believe that we might have any
            information from or about a child under age 18. Please contact us at
            info@onescribe.app
          </p>
        </div>
        <div className="changes-policy">
          <h2>Changes to Policy</h2>

          <p>
            We reserve the right to update / modify Policy from time to time, to
            reflect changes to Our Information practices. Any changes will be
            effective immediately upon the posting of the revised Policy on the
            Platform. If We make any changes, We will notify You by app
            notification, email or any other communication means prior to the
            change becoming effective. We encourage you to periodically review
            this page for the latest information on our privacy practices. Your
            use of the Onescribe App or availing the Offerings after an updated
            Policy becomes effective will indicate Your acceptance of the
            updated Policy
          </p>
        </div>
        <div className="contact-us">
          <h2>Contact Us:</h2>
          <p>
            If You have questions, concerns, or suggestions regarding Our
            Privacy Policy, We can be reached using the contact information on
            Our “Contact Us” page or at info@onescribe.app
          </p>
        </div>
      </Container>
    </section>
    <Footer/>
    </>
  );
}

export default PrivacyPolicy;
