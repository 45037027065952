import React from "react";
import "./otherdevicespage.css";
import gplay from "../../assets/navbar/googleplay.png";
import appstore from "../../assets/navbar/appstore.svg";

function OtherDevicesPage() {
  return (
    <section id="other-devices-page">
      <div className="message-container">App available on Android and iOS.</div>
      <div className="store-icon">
        <img src={gplay} className="googleplay" />
        <img src={appstore} className="appstore" />
      </div>
    </section>
  );
}

export default OtherDevicesPage;
