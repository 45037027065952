import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/homepage/home";
import BlogPage from "./components/blogpage/blogpage";
import MoreBlog from "./components/blog/moreblogs/moreblogs";
import OtherDevicesPage from "./components/otherdevicespage/otherdevicespage";
import PrivacyPolicy from "./components/helpAndSupport/privacypolicy";
import TermsAndConditions from "./components/helpAndSupport/termsAndCondition";
import RefundPolicy from "./components/helpAndSupport/refundAndCancellationPolicy";
import { RotatingSquare } from "react-loader-spinner";
import MoreFaq from "./components/faq/morefaq/morefaq";

const InitialLoader = () => {
  return (
    <div className="loader-container">
      <RotatingSquare
        height="100"
        width="100"
        color="#FFBE00"
        ariaLabel="rotating-square-loading"
        strokeWidth="4"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 5); // Adjust the delay time as needed
  }, []);

  return (
    <div
      className="App"
      style={{
        visibility: isLoading ? "hidden" : "visible",
        opacity: isLoading ? 0 : 1,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/moreblogs" element={<MoreBlog />} />
          <Route path="/blogpage/:id" element={<BlogPage />} />
          <Route
            exact
            path="/otherdevicespage"
            element={<OtherDevicesPage />}
          />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route
            path="/refund-and-cancellation-policy"
            element={<RefundPolicy />}
          />
          <Route path="/morefaq" element={<MoreFaq />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
