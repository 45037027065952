import React, { useState, useEffect } from "react";
import "./faq.css";
import Accordion from "react-bootstrap/Accordion";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl, config } from "../../components/network/constants/Constants";


function Faq() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [faq, setFaq] = useState([]);

  useEffect(() => {
    fetchFaq();
  }, []);

  const fetchFaq = () => {
    setLoading(true);

    axios
      .get(baseUrl + "app/faq/")
      .then((response) => {
        setLoading(false);

        setFaq(response.data);
      })
      .catch((error) => {
        setLoading(true);
      });
  };

  function HandleNavigation() {
    navigate("/morefaq");
  }

  return (
    // Conditionally render the entire section
    faq.length > 0 ? (
      <section id="faq">
        <div className="faq-section">
          <div className="faq-container">
            <Container>
              <div className="main-heading faq-head">FAQ</div>
              <div className="faq-questions">
                <Accordion>
                  {faq
                    .sort((a, b) => (a.id > b.id ? -1 : 1))
                    .slice(0, 4) // Display only the first 4 items
                    .map((qa, index) => (
                      <Accordion.Item key={qa.id} eventKey={index.toString()}>
                        <Accordion.Header>
                          <div className="question">{qa.question}</div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="answer">{qa.answer}</div>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                </Accordion>
              </div>
              {faq.length > 4 && (
                <div className="faq-btn">
                  <Button className="faq-view-more" onClick={HandleNavigation}>
                    View more
                  </Button>
                </div>
              )}
            </Container>
          </div>
        </div>
      </section>
    ) : null
  );
}

export default Faq;
