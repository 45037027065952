import React, { useState, useEffect } from "react";
import "./blogpage.css";
import NavBar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import { useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import gplay from "../../assets/navbar/googleplay.png";
import appstore from "../../assets/navbar/appstore.svg";

function BlogPage() {
  const location = useLocation();
  const blogData = location.state;

  const [isAndroid, setIsAndroid] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // useEffect(() => {
   
  //   window.scrollTo(0, 0);
  // }, [location]);

  const handleDownloadClick = () => {
    if (isAndroid) {
      // Navigate to the Play Store for Android
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.unacademyapp";
    } else if (/iphone|ipad|ipod|macintosh/i.test(navigator.userAgent)) {
      // Navigate to the App Store for iOS
      window.location.href =
        "https://apps.apple.com/in/app/udemy-online-video-courses/id562413829";
    } else {
      // Show the modal if the device is not Android or iOS
      setShowModal(true);
    }
  };

  if (!blogData) {
    return <div>No blog data available.</div>;
  }

  return (
    <section id="blogpage">
      <NavBar />
      <Container>
        <div className="blogpage-section">
          <div className="blogpage-head">{blogData.blog_title}</div>
          <div className="personal-details">
            <div className="personal-photo">
              <img
                src={blogData.blog_author_image}
                alt="person"
                className="personal-img"
              />
            </div>
            <div className="author-name">
              Written by
              <span className="author-name-span">{blogData.blog_author}</span>
              <span className="seperator-span">|</span> {blogData.blog_date}
            </div>
          </div>
          <div className="blogpage-image">
            <img
              src={blogData.blog_image}
              alt="blog"
              className="blogpage-img"
            />
          </div>
          <div
            className="blogpage-content"
            dangerouslySetInnerHTML={{
              __html: blogData.blog_body,
            }}
          />
        </div>

        <div className="nav-btn">
          <button className="download-button" onClick={handleDownloadClick}>
            Download Now
          </button>

          {/* Modal for download options */}
          <Modal centered show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="message-container">
                App available on Android and iOS.
              </div>
              <div className="store-icon">
                <img src={gplay} alt="Google Play" className="googleplay" />
                <img src={appstore} alt="App Store" className="appstore" />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="navbar-button"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </Container>
      <Footer />
    </section>
  );
}

export default BlogPage;
