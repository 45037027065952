import React, { useState, useEffect } from "react";
import "./morefaq.css";
import Accordion from "react-bootstrap/Accordion";
import { Container } from "react-bootstrap";
import NavBar from "../../navbar/navbar";
import Footer from "../../footer/footer";
import { IoSearchOutline } from "react-icons/io5";
import axios from "axios";
import { baseUrl } from "../../../components/network/constants/Constants.js";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';


function MoreFaq() {
  const [loading, setLoading] = useState(false);
  const [faq, setFaq] = useState([]);
  const [searchValue, setSearchValue] = useState(""); 

  useEffect(() => {
    fetchFaq();
  }, []);

  const fetchFaq = () => {
    setLoading(true);
    axios
      .get(baseUrl + "app/faq/")
      .then((response) => {
        setLoading(false);
        setFaq(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching FAQs:", error);
      });
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };


  const filteredFaq = faq.filter((qa) =>
    qa.question.toLowerCase().includes(searchValue.toLowerCase())
  );


  const displayFaqs = searchValue ? filteredFaq : faq;

  return (
    <section id="morefaq">
      <NavBar />

      <div className="more-faq-section">
        <Container>
          <div className="main-heading more-faq-head">FAQ</div>
          <div className="search-bar">
          <TextField id="search" name="search" className="search-field" placeholder="Search here..."value={searchValue}
              onChange={handleSearchChange} variant="outlined"  InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }} />
          
          </div>
          <div className="faq-questions">
            <Accordion>
              {displayFaqs.map((qa, index) => (
                <Accordion.Item key={qa.id} eventKey={index.toString()}>
                  <Accordion.Header>
                    <div className="question">{qa.question}</div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="answer">{qa.answer}</div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </Container>
      </div>
      <Footer />
    </section>
  );
}

export default MoreFaq;

