import React from "react";
import "./disclaimer.css";
import { Container } from "react-bootstrap";

function Disclaimer() {
  return (
    <section id="disclaimer">
      <div className="disclaimer-bg">
        <Container>
          <span className="desclaimer-head">Disclaimer :</span>&nbsp;The logos
          used in this website remain the exclusive intellectual property of
          their respective owners. The logos provided in this Website are solely
          intended for the purpose of enhancing the visual appeal and context of
          this Website.
        </Container>
      </div>
    </section>
  );
}

export default Disclaimer;
