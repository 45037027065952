import React from "react";
import "./about.css";
import { Col, Container, Row } from "react-bootstrap";
import arrow from "../../assets/about/Arrow 1.svg";
import one from "../../assets/about/one.svg";
import mobile1 from "../../assets/about/iphone1.png";
import mobile2 from "../../assets/about/iphone2.png";

function About() {
  return (
    <section id="about">
      <Container>
        <div className="about-row">
          <Row>
            <Col lg={5}>
              <img src={arrow} alt="arrow" className="down-arrow" />

              <div className="about-col">
                <div className="about-data">
                  <img src={one} alt="one" className="about-one" />
                  <div className="about-contents">
                    <div className="main-heading about-head">
                      About 1 Scribe
                    </div>
                    <div className="about-desc">
                      Onescribe is a technology platform building solutions for
                      customers recurring needs using subscriptions while
                      empowering SMBs with retention and cash flow. Our platform
                      serves multiple needs of both our patrons while delivering
                      real value. Bringing new experience of shopping with
                      subscription. Think subscriptions think Onescribe.
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={7}>
              <div className="mobile">
                <img src={mobile1} alt="mobile" className="mobile1" />
                <img src={mobile2} alt="mobile" className="mobile2" />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
}

export default About;
