import React from "react";
import "./helpandsupport.css";
import { Container } from "react-bootstrap";
import NavBar from "../navbar/navbar";
import Footer from "../footer/footer";

function TermsAndConditions() {
  return (
    <>
      <NavBar />
      <section id="termsandconditions">
        <Container className="terms-condition-container">
          <div className="main-heading Terms-head">Terms and Conditions</div>
          <div className="Terms-content">
            <p>
              This document is an electronic record in terms of Information
              Technology Act, 2000 and rules there under as applicable and the
              amended provisions pertaining to electronic records in various
              statutes as amended by the Information Technology Act, 2000. This
              document is published in accordance with the provisions of Rule 3
              (1) of the Information Technology (Intermediaries guidelines)
              Rules, 2011 that require publishing the rules and regulations,
              privacy policy and Terms of Use for access or usage of Website and
              Application.
            </p>
            <p>
              <b>CAUTION:</b> Please read the Terms before using the Platform or
              registering on the Platform or accessing any information or
              Offerings through the Platform. If You do not agree to these
              Terms, You may not use the product/ services on the Platform and
              We request You to uninstall the App. By even merely using/
              accessing the Platform, You signify Your acceptance to these
              Terms. And through the continued use of the Platform You agree to
              comply with and be bound by the following Terms and Conditions of
              Use and Our Privacy Policy.
            </p>
            <h2>GENERAL:</h2>
            <p>
              <b>A.</b> We
              <b>Moblio Future Technologies Private Limited (Onescribe)</b>, a
              Company registered under Companies Act 2013 and having its
              registered office at 3rd floor building no. 60/49E JC Chambers, V
              309, Opp Kairali flat, Panampilly Nagar, Ernakulam-682036, India
              hereinafter referred to as the “Company” (where such expression
              shall, unless repugnant to the context thereof, be deemed to
              include its respective legal heirs, representatives,
              administrators, permitted successors and assigns).
            </p>
            <p>
              The Company ensures steady commitment to your usage of the
              Platform and privacy with regard to the protection of your
              valuable information. This document contains information about the
              Website and domain www.onescribe.app and mobile application
              “Onescribe, Onescribe partner app” (hereinafter referred to as the
              “Platform”).
            </p>
            <p>
              <b>B.</b> These terms of use (“Terms”)is a legal binding document
              between You & Us, and govern Your use of the Platform, and for the
              purpose of these Terms the Company may wherever context so
              require, be also referred to as, ”Company” “Onescribe”, "We",
              "Us", or "Our" and the terms “You”, “Your” or “User” refer to user
              of the Platform. The Platform may be used by Users to avail
              products/ services (collectively “Offerings”) through merchants,
              Individual seller or service providers hereinafter (collectively
              “Partners”), wherever the context so requires.
            </p>
            <p>
              <b>C. </b>Upon Your consent to the Terms of Use and other
              policies, We hereby grant You a limited, personal, revocable,
              non-assignable, non-transferable and non-exclusive license to use
              Our Offerings. This license is for the sole purpose of enabling
              You to use the services in the manner expressly permitted by this
              Terms of Use and Our other policies.
            </p>
            <div className="territory">
              <h2>TERRITORY:</h2>

              <p>
                The Website or mobile application and the services and any
                purchase are directed solely at those who access the Platform
                from the Republic of India. Onescribe makes no representation
                that Offerings are available or otherwise suitable for use
                outside the Republic of India. If you choose to access the
                Platform (or use the Offerings or make a subscription) from
                locations outside the Republic of India, you do so on your own
                initiative and are responsible for the consequences thereof.
              </p>
            </div>
            <div className="eligibility">
              <h2>ELIGIBILITY:</h2>

              <p>
                The Platform, and by extension, the Onescribe Offerings, are not
                available to minors i.e. persons under the age of 18 (eighteen)
                years or to any Users suspended or removed by Onescribe from
                accessing the Platform for any reason whatsoever. While
                individuals under the age of 18 may utilize/browse the Onescribe
                Platform, they shall do so only with the involvement, guidance
                and supervision of their parents or under their registered
                account. We reserves the right to terminate User’s access and
                refuse to provide User with access to the Onescribe Platform if
                We discovers that User is under the age of 18 years.
              </p>
            </div>
            <div className="amendments">
              <h2>AMENDMENTS:</h2>
              <p>
                The company at all times reserve the right to amend these terms
                and conditions at any time. All amendments and changes to these
                terms and conditions will be available online on the “Last
                Updated” legend above. If we make changes to Our Terms and
                Privacy Policy and You continue to use Our website or mobile
                application, You are deemed to have implicitly agreed to the
                amended Terms and Privacy Policy. Unless specified otherwise,
                any such deletions or modifications shall be effective
                immediately upon Onescribe’s posting thereof. We suggest You to
                re-read Our Terms and Privacy Policy from time to time so that
                You remain informed as to any such changes.
              </p>
            </div>
            <div className="account-creation">
              <h2>ACCOUNT CREATION:</h2>
              <p>
                Opening an account is free. In order to subscribe and/ or avail
                Offerings, You will have to sign-up/ register on the Platform.
                Registration is a one-time process based on successful mobile
                OTP (One Time Password) verification. At different times during
                Your use of Platform We may ask You with further details such as
                but not limited to (email, delivery address) for providing Our
                Offerings.{" "}
              </p>

              <p>
                You agree and accept that the information provided by You for
                the creation of the account is complete, true, accurate and
                up-to-date. In the event of any change to such information, You
                are required to immediately update their Account information.
              </p>
              <p>
                You are responsible for all or any activities that occur under
                Your account, and You agree to maintain the security and secrecy
                of Your account at all times. You agree to immediately notify Us
                of any unauthorized access of Your account. You may be held
                liable to pay damages for losses caused due to unauthorized use
                of Your account as a result of Your failure to keep Your account
                information secure and confidential.
              </p>
              <p>
                Unless otherwise permitted by Onescribe in writing You may only
                possess one active account.
              </p>
            </div>
            <div className="service">
              <h2>ONESCRIBE SERVICES:</h2>
              <p>
                The Platform acts as an aggregator and connects the Users with
                subscription based Offerings from Partner seller in Your
                locality or any parts in India. The Platform provide You with
                following:
              </p>

              <ol>
                <li>
                  it facilitate Users to subscribe to products/ subscription box
                  from the local merchants in their community or any seller in
                  India.
                </li>
                <li>
                  it facilitate Users to subscribe services but not limited to
                  that requires reservation such as theater, clinics, bus,
                  restaurants, car wash, saloon, GYM etc. from local merchants,
                  in their community or any seller in India.
                </li>
              </ol>
              <p>
                Before subscribing for availing any Offerings, We request You to
                please check the Offerings available in Your area or city. The
                Offerings provided in one region may differ from those in other
                regions due to availability, local or regional laws and other
                considerations. We do not make any warranty or representation
                that a User in one region may obtain the Offerings from Our site
                in another region and We may cancel User's request and redirect
                to the site for that User’s region if a User attempts to
                subscribe from another region.
              </p>
              <p>
                Subscriptions placed by You on the Platform are undertaken
                directly by the Our Partners against whom You have subscribed
                and in this case Onescribe is acting merely as an intermediary
                between You and Partner and shall not be responsible for any
                manner of Partners (including their employees), actions,
                conduct, misbehavior, or any other legal issues and also shall
                not be liable to any Offerings provided or performed by Partner
                as the case may be. We request You to follow all rules &
                regulations of Partners if any during Your access to the
                Offerings. Any Offerings availed which do not fall within the
                ambit of the subscription package availed will have to be paid
                by the User directly at the facility.
              </p>
              <p>
                The Company does not provided any guarantee that the Offerings
                will be made available to Users at all times. User also
                understand and acknowledge that Onescribe does not sell or
                provide any Offerings and is not responsible for quality,
                merchantability or fitness of any Offerings. If any complaints
                with respect to any Onescribe Offerings User may contact within
                5 days of using such. Users can also call us on 24x7 support
                line.
              </p>
            </div>
            <div className="purchase">
              <h2>SUBSCRIPTION PURCHASE, REDEMPTION and USAGE:</h2>

              <p>
                Users can subscribe to various Offerings of Partners from the
                Platform. All the subscriptions are made available from Our
                participating stores & Partners and not everyone. Once
                subscription has confirmed We will send the confirmation via SMS
                or push notification in the app.
              </p>
              <p>
                Order Placement: Upon choosing an Offering You confirm it after
                paying the amount through payments methods available on the
                Platform.
              </p>
              <p>
                In case of multiple Offerings subscribed from the same Partner,
                You and Partner are responsible to provide the valid
                subscription You have availed. Onescribe shall not be held
                responsible in case the User or Partner shared incorrect
                information of the usage.
              </p>
              <p>
                Redemption: Redemption of the Offerings are facilitated through
                QR code. In order to redeem Your subscription against eligible
                Offerings, You will need to present Your QR code to the
                merchant’s scanner to confirm. In any failure to do so Partner
                reserves right to suspend Your usage and charge You the as per
                their cost. Onescribe shall not be liable for any such actions.
                You should keep your QR code confidential, and You are not
                permitted to share it with any other person.
              </p>
              <p>
                Usage: You will be able to only use Offerings that You have
                subscribed. Any additional usage of Offerings out of package
                will incur a fee and You are responsible to pay the additional
                amount. For the Offerings that need reservations We request You
                to do it in prior to Your arrival and to rules & regulations of
                Partners. Any conversations regarding Your reservation are done
                by You and Partner and Onescribe has no control over it. Failing
                to do so may result in loss, usage or unavailability of
                Offerings. Any issue regarding this shall be settled between You
                and Partner.
              </p>
            </div>
            <div className="modification">
              <h2>MODIFICATION TO SERVICES AND PRICING OF SUBSCRIPTION:</h2>

              <p>
                Onescribe reserves the right to modify structure or discontinue
                the features, functionality and other attributes of the
                Offerings at any time in its sole discretion. We serves as an
                intermediary between the Partners and Users, facilitating access
                to the Offerings provided by the Partner. We may communicate
                updates or changes to You on behalf of the Partner, We does not
                have the authority to unilaterally modify plans of the Offerings
                or pricing. Partner reserves the right to modify, suspend or
                discontinue any aspects of the Offerings, including features,
                functionalities and availability at any time. Changes to prices
                and Offerings will be effective upon posting on the Platform to
                new Users and from the next billing cycle to existing Users.
              </p>
              <p>
                We do not warrant that the quality of any Offerings,
                information, or other material purchased or obtained by You will
                meet Your expectations, or that any errors in the Offerings will
                be corrected. We have made every effort to display as accurately
                as possible the colors and images of the Offerings but You agree
                and understand that they are just representation and not actual.
                We cannot guarantee that your computer monitor’s display of any
                color will be accurate and will not be responsible or liable for
                the same. We shall not be liable to You or to any third-party
                for any modification, price change, suspension or discontinuance
                of the Offerings.
              </p>
            </div>
            <div className="billing">
              <h2>SUBSCRIPTION BILLING AND RENEWAL:</h2>

              <p>
                Some or every part of the Offerings is billed on a subscription
                basis. Users subscribe to Offerings with different features,
                benefits and pricing option. Each plan has its own T&C,
                including billing cycle, renewal period and pricing. You agree
                to pay amount in advance to use/avail Offerings. Billing cycle
                and price will be clearly stated in the description of the
                Offerings. The customers billing cycle starts immediately when
                the Offerings is subscribed. Non-payments or delayed payments of
                renewal payments could result in termination of subscription
                usage until You renew to restart the usage.
              </p>
            </div>
            <div className="fees-payment">
              <h2>FEES AND PAYMENT OPTIONS:</h2>

              <p>
                We reserve the right to charge You according to the Offerings
                You have subscribed from time to time on the Platform according
                to the plan, validity and price. In addition to the Offerings
                price, We reserves the right to charge certain fees in the
                nature of subscription fee, convenience fees, service fees,
                fulfilment fees and processing fees. The User will be informed
                about all the charges, fees and costs that may be levied on at
                the checkout page during transaction. (Devp)
              </p>
              <p>
                Any increase in the price charged by Onescribe on account of
                change in rate of taxes or imposition of new taxes, levies by
                Government shall have to be borne by the User. Such imposition
                of taxes, levies may be without prior notice and could also be
                retrospective but will always be as per applicable law. The User
                shall be completely responsible for all charges, fees, duties,
                taxes, and assessments arising out of the use of the Offerings,
                as per the applicable laws.
              </p>
              <p>
                In order to ensure User convenience, Onescribe provide multiple
                payment options to Users on Our Platforms, like UPI,
                Credit/Debit cards, Net banking or any other RBI approved
                payment options.
              </p>
              <p>
                We may use a third-party payment processor (“Razorpay”) to bill
                You through Your selected mode of payment. The processing of
                payments will be subject to the terms and policies of such
                Payment Processor in addition to these Terms. We shall not be
                liable for any error of the Payment Processor. In the event of
                any unsuccessful payment, the money debited shall be credited in
                accordance with the terms of the Payment Processor.
              </p>
              <p>
                All payments made against the subscription of Offerings on the
                Platform by You shall be compulsorily in Indian Rupees
                acceptable in the Republic of India. The Platform will not
                facilitate transactions with respect to any other form of
                currency with respect to the purchases made on Platform.
                Onescribe does not control (and thus cannot disclose) fees
                levied by Your bank and/or credit card company, transaction
                charge, including fees for subscribing Offerings in foreign
                currencies or from foreign persons. You agree and acknowledge to
                pay any such fees. Be sure to check with Your bank or credit
                card company prior to engaging in a transaction to understand
                all applicable fees, credit card surcharges and currency
                conversion rates. We have the right to modify and otherwise
                restrict the modes of payment available to you.
              </p>
              <p>
                Company is not liable for any payments that do not complete
                because: (a) Your bank account or any other payment did not
                contain sufficient funds to complete the transaction; (b) You
                have not provided the Company with correct Payment Details; (c)
                Your payment card has expired; or (d) circumstances beyond the
                Company’s reasonable control (such as, but not limited to, power
                outages, interruptions of cellular service, or any other
                interferences from an outside force) which prevent the execution
                of the transaction.
              </p>
              <p>
                The User agrees and understands that all payments shall only be
                made to bank accounts of Onescribe. Company or its authorised
                representatives will NEVER ask You to share Your debit/ credit
                card number, CVV, OTP, UPI/ATM pin and other sensitive
                information. We request You not to share aforesaid details with
                anyone or anyone claiming to a Company representative. Beware of
                fraudsters and please report incidents immediately to Your bank,
                the nearest police station and at https://cybercrime.gov.in
              </p>
            </div>
            <div className="cancellation">
              <h2>CANCELLATION AND REFUNDS:</h2>

              <p>
                If You wish to cancel Your subscription, You can do it from the
                cancel option available on My Subscription page on the app
                anytime. In the event of cancellation, users shall be subject to
                a cancellation fee equivalent to the cost of one usage.
                Cancellation are not possible after fixed visits/usage and are
                different for each Partners and are set by Partner itself and is
                mentioned in Offering description. In case of subscription
                cancellation by You any fees collected other than Offerings
                price in non-refundable.
              </p>
              <p>We reserve the rights to cancel Your subscription if; </p>

              <ol>
                <li>
                  Your failure to give enough information to provide Our
                  Offerings
                </li>
                <li>unavailability of Offerings by Partners</li>
                <li>unavailability or closure of Partner’s outlet</li>
                <li>
                  failure due to any unforeseen reason or any attributable to
                  the Partners
                </li>
                <li>if You don’t have a valid Onescribe account. </li>
              </ol>

              <p>
                Refund Policy: You are entitled to cancel and get refund upon
                Offerings if;
              </p>

              <ol>
                <li>discontinuation of Offerings by Partner </li>
                <li>
                  relocating of business by Partners outside Our service area
                </li>
                <li>change in ownership or business </li>
                <li>discontinuation of the subscribed plan;</li>
                <li>for any reason attributable to Us or Partners</li>
              </ol>
              <p>
                Users requesting cancellation and refunds must submit their
                request detailing the reason with any relevant supporting
                documentation. All cancellation and refund requests will be
                reviewed by the Platform's customer support team. And all
                decisions with respect to refunds will be at the sole discretion
                of Onescribe and in accordance with Onescribe’s internal refund
                policy and the same shall be final and binding. You are not
                entitled to refund for:
              </p>
              <ol>
                <li>modification of price/benefits by Partners</li>
                <li>Your unsatisfication of the Offerings provided </li>
                <li>Your unuse of the Offerings </li>
                <li>in breach of this terms</li>
                <li>force majeure</li>
              </ol>

              <p>
                Our decision on refunds and cancellations shall be final and
                binding.{" "}
              </p>
              <p>
                Your refund will be processed only when the conditions as may be
                stipulated by Us are fulfilled. All refunds initiated by
                Onescribe shall be refunded to the financial source account from
                which Users have initiated the transaction on the Platform. You
                acknowledge that after initiation of refund, it may take
                additional time for Your refund to reflect in Your account which
                is subject to Your financial institution or payment gateway
                service provider terms and conditions.
              </p>
              <p>
                In case the refund amount is not credited to your account in
                9-15 working days from the date of refund, we kindly request you
                to send an email to info@onescribe.app along with the
                subscription details. We will provide you the necessary support
                in crediting back the refund amount.
              </p>
            </div>
            <div className="disclaimer-warranties">
              <h2>DISCLAIMER OF WARRANTIES:</h2>

              <p>
                You acknowledge and agree that the Offerings are provided “AS
                IS” and “AS AVAILABLE” basis without any warranties or
                representations, express or implied. The User agrees and
                undertakes that they are accessing the Platform at their sole
                risk and are that they are using their best and prudent judgment
                before availing any Offerings listed on the Platform or
                accessing any information displayed thereon. The Platform does
                not guarantee that the Offerings in the Platform will meet User
                requirement; will be free of viruses or other harmful
                components, uninterrupted or error-free, or that any defects or
                errors will be corrected. The Platform does not warrant the
                accuracy, reliability, completeness, or timeliness of any
                information, content, materials or Offerings provided and You
                are solely responsible for verifying any information obtained
                through the Platform.
              </p>
              <p>
                You agree and acknowledge that We are merely a Platform that
                connects You with Partners selling subscription based Offerings,
                and We shall not be liable in any manner for any obligations
                that have not been explicitly stated in these Terms during Your
                access of Offerings. By subscribing You are entering into a
                contract with relevant Partner for availing their Offerings and
                we accept no responsibility or liability, nor do make warranty,
                representation, or guarantee in respect of the Offerings
                provided under that contract. We shall not in any way
                responsible for monitoring any transaction between You and
                Partners. You are solely responsible for Your communication and
                interactions with other/whom You communicate/interact as a
                result of using the Offerings. No advice or information, whether
                oral or written, obtained by You from Us shall create any
                warranty that is not expressly stated in the Terms.
              </p>
              <p>
                We shall not be held responsible for nonfunctioning,
                nonoperational, non-availability of the Platform during upgrades
                and periodic maintenance operations or any unplanned suspension
                of access to the Platform that may occur due to technical
                reasons or for any reason beyond Our control. Users understand
                and agree that any material or data downloaded or otherwise
                obtained through the Platform is done entirely at their own
                discretion and risk, and that they will be solely responsible
                for any damage to their computer systems or loss of data that
                results from the download of such material or data.
              </p>
            </div>
            <div className="limitation">
              <h2>LIMITATION OF LIABILITY:</h2>
              <p>
                All Onescribe Offerings are owned, operated and provided by
                Partners. After subscribing We do not hold any liability for any
                occurrence of any mishap on Offerings resulting in any material
                or human damage. You understand and agree that in no event shall
                Onescribe, its officers, directors, and employees, or its
                contractors, agents, licensors, partners, or suppliers be liable
                to You for any direct, indirect, incidental, special,
                consequential or exemplary damages, including without limitation
                damages for loss of profits, goodwill, use, data or other
                intangible losses (even if Onescribe had been advised of the
                possibility of such damages), resulting from or relating to the
                Platform, whether based on warranty, contract, tort, or any
                other legal theory.
              </p>
              <p>
                To the fullest extent permissible by law, We, Our affiliates,
                and Our related parties, each disclaim all liability for any
                loss or damage arising out of, or due to:
              </p>

              <ol>
                <li>Errors, mistakes or inaccuracies of Offerings</li>
                <li>
                  Your use of, inability to use, or availability or
                  unavailability of the Offerings
                </li>
                <li>
                  the occurrence or existence of any defect, interruption, or
                  delays, in the Offerings, communications failure, theft,
                  destruction, or unauthorized access to Our records,
                  programmes, services, servers, or other infrastructure
                  relating to the Offerings;
                </li>
                <li>
                  the failure of the Offerings to remain operational for any
                  period of time;
                </li>
                <li>
                  Your failure to keep password or account details secure and
                  confidential;
                </li>
                <li>
                  the loss of any User data and any other data in connection
                  with Your use of the Platform.
                </li>
              </ol>

              <p>
                All claims/complaints arising from and in connection with the
                use of Onescribe Offerings shall be promptly submitted or
                reported to Onescribe within 30 days of the consumption of such
                Offerings. Any claim or complaint that is submitted/reported
                after the period of 30 days may be rejected, and the claimant
                will forfeit the right to claim any damage, cost or
                compensation.
              </p>
            </div>
            <div className="third-party">
              <h2>THIRD PARTY LINKS AND ADVERTISEMENTS:</h2>

              <p>
                The Platform may contain links to third-party websites,
                advertisements, services, offers, or other content that are not
                owned or controlled by the Platform. If Users decide to access
                any such linked third party website, they do so at their own
                risk. Before using/ accessing any third party links/
                advertisement We request You to review applicable terms and
                polices of such third parties.
              </p>

              <p>
                We have no control over, and make no representation or
                endorsement regarding the accuracy, relevancy, copyright
                compliance, legality, completeness, timeliness or quality of any
                product, services, advertisements and other content appearing in
                or linked to from the Platform. You further agree third party
                content does not reflect Our views or that of Our parent,
                subsidiary, affiliate companies, branches, employees, officers,
                directors, or shareholders. We do not screen, examine, evaluate
                or investigate third party material before or after including it
                on Our Platform. You also agree that Onescribe is not liable for
                any loss or damage which may be incurred by Your use of third
                party content. Without limiting the generality of the foregoing,
                We expressly disclaim any liability for any offensive,
                defamatory, illegal, invasive, unfair, or infringing content
                provided by third parties.
              </p>
            </div>
            <div className="reviews-feedbacks">
              <h2>REVIEWS, FEEDBACKS AND OTHER SUBMISSIONS:</h2>

              <p>
                Users may have the opportunity to submit reviews, feedback,
                comments, suggestions, and other content ("Submissions") through
                the Platform, email or otherwise by postal. By making
                Submissions, Users grant the Platform a non-exclusive,
                royalty-free, perpetual, irrevocable, and fully sublicensable
                right to use, reproduce, modify, adapt, publish, translate,
                create derivative works from, distribute, and display such
                Submissions worldwide in any media. Users acknowledge and agree
                that Submissions may be publicly visible on the Platform and may
                be used by the Platform for promotional and marketing purposes.
                Users are solely responsible for the content of their
                Submissions accuracy and represent and warrant that their
                Submissions do not infringe upon the intellectual property
                rights, privacy rights, or any other rights of any third party.
                The Platform reserves the right to monitor, edit, or remove any
                Submissions that violate the Terms and Conditions or are deemed
                inappropriate, offensive, or unlawful. You acknowledge and agree
                that the Platform has no obligation to respond to or act upon
                any Submissions; to maintain any Submissions in confidence; or
                to pay compensation for any Submissions provided by You. You
                further agree that Your Submissions will not contain libelous or
                otherwise unlawful, abusive or obscene material, or contain any
                computer virus or other malware that could in any way affect the
                operation of the service or any related website.
              </p>
            </div>
            <div className="intellectual">
              <h2>INTELLECTUAL PROPERTY RIGHTS:</h2>

              <p>
                The Company is the sole owner and lawful licensee of all the
                rights to Platform and shall be the exclusive, owner of all the,
                trademarks, copyrights, designs, graphics and the like, related
                to the Platform.Unless expressly agreed to in writing, nothing
                contained herein including but not limited to, all text, videos,
                graphics, user interfaces, visual interfaces, sounds and music
                (if any), artwork, algorithm and computer code (and any
                combination thereof), compilations and databases of accounts and
                account details, website, domains except any third party
                software available on the Platform, is owned by Onescribe
                (collectively, the “Content”) and the design, structure,
                selection, co-ordination, expression, look and feel and
                arrangement of such Content is protected by copyright and
                trademark laws, and various other intellectual property rights.
              </p>
              <p>
                The trademarks, logos and service marks displayed on the
                Platform (“Marks”) are the property of Onescribe, except any
                trademark, logos and service marks of third parties available on
                the Platform. Users are not permitted to use the Marks without
                the prior consent of Onescribe or such third party as may be
                applicable. All titles, ownership and Intellectual Property
                Rights in the Platform and its Content remain with the Company
                except the third party content and link to third party website
                on the Platform.
              </p>
              <p>
                We at our discretion, remove or disable access to Offerings on
                the Platform that infringes upon the copyright rights of others.
                If You come into notice that Your/ others intellectual property
                rights have been violated/used in any manner which results in
                infringement, then please inform us at info@onescribe.app We
                will evaluate the Contents of Your notice and will respond
                within a reasonable period of time, We may also reach out to You
                for additional details. It may be noted that reporting
                inaccurate, false or misleading information to Onescribe may
                subject to criminal liability.
              </p>
            </div>
            <div className="prohibited">
              <h2>PROHIBITED USE:</h2>

              <p>
                In addition to other prohibitions as set forth in the Terms of
                Service, You are prohibited from using the site, mobile
                application or its content:
              </p>
              <ol>
                <li>for any unlawful purpose</li>
                <li>
                  to violate any international, federal, provincial or state
                  regulations, rules, laws, or local ordinances currently in
                  force within or outside India
                </li>
                <li>do any such thing that may harms minors in any way</li>
                <li>
                  to harass, abuse, insult, harm, defame, slander, disparage,
                  intimidate, or discriminate based on gender, sexual
                  orientation, religion, ethnicity, race, age, national origin,
                  or disability
                </li>
                <li>to submit false or misleading information</li>
                <li>
                  to upload or transmit viruses or any other type of malicious
                  code that will or may be used in any way that will affect the
                  functionality or operation of the service or of any related
                  website, or the Internet
                </li>
                <li>
                  to collect, store or track the personal information of Users,
                  Partners and others including; (prohibited use in business
                  terms too)
                </li>
                <li>to spam, phish, pharm, pretext, spider, crawl or scrape</li>
                <li>
                  to sell, trade or otherwise transfer their account to another
                  party or person
                </li>
                <li>for any obscene or immoral purpose</li>
                <li>
                  to cause confusion about the owner or authorized user of Your
                  use of any trademark, service mark or trade name
                </li>
                <li>
                  to interfere with or circumvent the security features of the
                  Service or any related website, other websites, or the
                  Internet
                </li>
                <li>
                  to share or transfer Your account or QR code to any other
                  person or third party
                </li>
                <li>
                  to copy, republish, duplicate, post, display, translate,
                  transmit, reproduce or distribute any Onescribe content
                  through any medium without obtaining the necessary
                  authorization from Onescribe.
                </li>
                <li>
                  to threaten the unity, integrity, defence, security or
                  sovereignty of India.
                </li>
              </ol>

              <p>
                We reserve the right to terminate Your use of the Service or any
                related website for violating any of the prohibited uses. We may
                also in its sole discretion and at any time, discontinue the
                Platform with or without notice.
              </p>
            </div>
            <div className="suspension-termination">
              <h2>SUSPENSION AND TERMINATION:</h2>

              <p>
                This User Agreement is effective unless and until terminated,
                either by you or by the Company. You may terminate this at any
                time by contacting Us via contact us page on the Platform. The
                Company may disable Your access or block Your future access to
                the Platform or suspend or terminate Your account at any time
                and for any reason without notice if any discrepancy or legal
                issue arises or, in its sole and absolute discretion, that You
                have violated any term of these Terms or the Privacy Policy or
                in any way otherwise acted unethically. In the event of
                suspension or termination, Users may lose access to the
                Platform's services and all associated benefits, including
                subscriptions and accumulated rewards or credits. Users found to
                be in violation of the Terms and Conditions may also be subject
                to additional legal action or penalties as permitted by law.
              </p>
            </div>
            <div className="communication">
              <h2>COMMUNICATIONS:</h2>
              <p>
                By creating an Onescribe account, User agree to accept and
                receive communications from Onescribe, Partners or any third
                party providers, via email, text message, calls, mobile
                notifications and push notifications to the cellular telephone
                number you provided to Onescribe. These communications include
                but not limited to provide subscription confirmation,
                cancellation, refund status, renewal date and price, or any such
                relevant information.
              </p>
              <p>
                If You do not wish to receive promotional emails, text messages,
                or other communications, you may opt out of such any time
                through specific channels of communications or by contacting
                customer support info@onescribe.app You may also opt-out of
                receiving promotional text messages from Onescribe by replying
                “STOP” from the mobile device receiving the messages.
              </p>
            </div>
            <div className="indeminity">
              <h2>INDEMINITY:</h2>

              <p>
                You shall indemnify, defend at Our option, and hold harmless Us,
                Our parent companies, subsidiaries, affiliates, and Our
                officers, employees, directors, agents, and representatives,
                harmless from and against any claim, demand, lawsuits, judicial
                proceeding, losses, liabilities, damages, and costs (including,
                without limitation, all damages, liabilities, settlements, and
                attorneys’ fees), due to or arising out of Your access to the
                Offerings, violation of these Terms, or any violation of these
                Terms by any third party who may use Your Account.
              </p>
            </div>
            <div className="governing-law">
              <h2>GOVERNING LAW & JURISDICTION:</h2>

              <p>
                All disputes arising out of or in doing business on the Platform
                shall be amicably settled at the first instance by mutual
                discussions and negotiations. In the event the dispute is not
                resolved then the same may be referred to arbitration in
                accordance with the provisions of the Arbitration and
                Conciliation Act, 1996 or any enactment of statutory
                modification thereof. The arbitration proceedings shall be in
                the English language and shall be held in Thrissur. The award of
                the arbitral tribunal shall be final and binding upon the
                parties and no appeal against the same shall lie to any court.
                The courts of competent jurisdiction in Thrissur shall have
                exclusive jurisdiction over any dispute, differences or claims
                arising out of this agreement
              </p>
            </div>
            <div className="grievances">
              <h2>GRIEVANCES:</h2>

              <p>
                Any and all communication relating to any dispute, complaints,
                concerns or grievance experienced by the User may be
                communicated to the Company by the User by emailing to
                info@onescribe.app . The company shall endeavor to resolve such
                grievances with in 30 days of notice.
              </p>
            </div>
            <div className="general-provisions">
              <h2>GENERAL PROVISIONS:</h2>

              <ol>
                <li>
                  <b>Notice</b>: All notices from the Company will be served by
                  messaging app on the registered mobile phone number or by
                  general notification on the Platform.
                </li>
                <li>
                  <b>Severability</b>: If any of these Terms of Use should be
                  determined to be illegal, invalid or otherwise unenforceable
                  by reason of the laws of any state or country in which Terms
                  of Use are intended to be effective, then to the extent and
                  within the jurisdiction where that term is illegal invalid or
                  unenforceable, it shall be severed and deleted and the
                  remaining Terms of Use shall survive, remain in full force and
                  effect and continue to be binding and enforceable.
                </li>
                <li>
                  <b>Assignment</b>: You cannot assign or otherwise transfer any
                  rights granted hereunder to any third party. The Company’s
                  rights under the Terms are freely transferable by the Company
                  to its successor or any third party without the requirement of
                  seeking Your consent.
                </li>
                <li>
                  <b>Force Majeure</b>: Without prejudice to any other provision
                  herein, Onescribe shall not be liable for any loss, damage or
                  penalty as a result of any delay in or failure to deliver or
                  otherwise perform hereunder due to any cause beyond
                  Onescribe’s control, including, without limitation, acts of
                  the User, embargo or other Governmental act, regulation or
                  request affecting the conduct of Onescribe’s business, fire,
                  explosion, accident, theft, vandalism, riot, acts of war,
                  strikes or other labor difficulties, lightning, flood,
                  windstorm or other acts of God.
                </li>
                <li>
                  <b>Third Party Rights</b>: No third party shall have any
                  rights to enforce any terms contained herein
                </li>
                <li>
                  <b>Waiver</b>: Any failure by Onescribe to enforce or exercise
                  any provision of the Terms of Use, or any related right, shall
                  not constitute a waiver by Onescribe of that provision or
                  right.
                </li>
              </ol>
            </div>
            <div className="contact-us">
              <h2>CONTACT US:</h2>
              <p>
                If you have any questions about this Policy, the practices of
                the Platform, or your experience with the Service provided by
                the Platform, you can contact us at info@onescribe.app
              </p>
            </div>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default TermsAndConditions;
