import React, { useState, useEffect } from "react";
import "./blog.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  baseUrl,
  config,
} from "../../components/network/constants/Constants.js";


function Blog() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [blog, setBlog] = useState([]);
  

  useEffect(() => {
    fetchBlog();
  }, []);

  const fetchBlog = () => {
    setLoading(true);
  
    axios
      .get(baseUrl + "web/blog-list/")
      .then((response) => {
        console.log("API response data:", response.data.results);
        setLoading(false);

       if (response.data && Array.isArray(response.data.results)) {
            setBlog(response.data.results);
        } else {
            console.error("Unexpected data format:", response.data);
            setBlog([]);
        }

      })
      .catch((error) => {
        setLoading(false); 
        console.error("Error fetching blog data:", error);
      });
  };


  

  function HandleNavigation() {
    navigate("/moreblogs");
  }

  function HandleBlogNavigation(id) {
    const blogItem = blog.find(item => item.id === id);
    navigate(`/blogpage/${id}`,{ state: blogItem });
  }

  return (
    <section id="blog">
      <div className="main-heading blog-mainhead">Blog</div>
      <Container>
        <div className="blog-row">
          <Row>
            <Col lg={1}></Col>
            {blog
              .slice(0, 2)
              .map((blogItem, index) => (
                <Col
                  lg={5}
                  key={blogItem.id}
                  onClick={() => HandleBlogNavigation(blogItem.id)}
                  eventKey={index.toString()}
                >
                  <div className="blog-col">
                    <div className="blog-data">
                      <div className="blog-image">
                        <img
                          src={blogItem.blog_image}
                          className="blog-img"
                          alt="blog"
                        />
                      </div>
                      <div className="blog-person">
                        <div className="photo">
                          <img
                            src={blogItem.blog_author_image}
                            alt="blogger"
                            className="blogger"
                          />
                        </div>
                        <div className="bloger-name ms-3">
                          {blogItem.blog_author}
                        </div>
                      </div>
                    </div>
                    <div className="date">{blogItem.blog_date}</div>
                    <div className="underline"></div>
                    <div className="blog-head">{blogItem.blog_title}</div>
                    <div
                      className="blog-content"
                      dangerouslySetInnerHTML={{
                        __html:
                          blogItem.blog_body.length > 350
                            ? blogItem.blog_body.slice(0, 350) + "..."
                            : blogItem.blog_body,
                      }}
                    />
                    <div className="blog-btn">
                      <Button
                        className="blog-button"
                        onClick={() => HandleBlogNavigation(blogItem.id)}
                      >
                        Read more
                      </Button>
                    </div>
                  </div>
                </Col>
              ))}
            <Col lg={1}></Col>
          </Row>
        </div>
        {blog.length > 2 && (
          <div className="see-morebtn">
            <Button className="see-more" onClick={HandleNavigation}>
              See more
            </Button>
          </div>
        )}
      </Container>
    </section>
  );
}

export default Blog;
