import React from "react";
import "./helpandsupport.css";
import { Container } from "react-bootstrap";
import NavBar from "../navbar/navbar";
import Footer from "../footer/footer";

function RefundPolicy() {
  return (
    <>
      <NavBar />
      <section id="refund-and-cancellation-policy">
        <Container>
          <div className="main-heading refund-head">
            Refund & Cancellation Policy
          </div>
          <div className="refund-content">
            <p>
              If You wish to cancel Your subscription, You can do it from the
              cancel option available on My Subscription page on the app
              anytime. In the event of cancellation, users shall be subject to a
              cancellation fee equivalent to the cost of one usage. Cancellation
              are not possible after fixed visits/usage and are different for
              each Partners and are set by Partner itself and is mentioned in
              Offering description. In case of subscription cancellation by You
              any fees collected other than Offerings price in non-refundable.
              We reserve the rights to cancel Your subscription if; (i) Your
              failure to give enough information to provide Our Offerings (ii)
              unavailability of Offerings by Partners (iii) unavailability or
              closure of Partner’s outlet (iv) failure due to any unforeseen
              reason or any attributable to the Partners (v) if You don’t have a
              valid Onescribe account. Refund Policy: You are entitled to cancel
              and get refund upon Offerings if; (i) discontinuation of Offerings
              by Partner (ii) relocating of business by Partners outside Our
              service area (iii) change in ownership or business (iv)
              discontinuation of the subscribed plan; (v) for any reason
              attributable to Us or Partners Users requesting cancellation and
              refunds must submit their request detailing the reason with any
              relevant supporting documentation. All cancellation and refund
              requests will be reviewed by the Platform's customer support team.
              And all decisions with respect to refunds will be at the sole
              discretion of Onescribe and in accordance with Onescribe’s
              internal refund policy and the same shall be final and binding.
              You are not entitled to refund for: (i) modification of
              price/benefits by Partners (ii) Your unsatisfication of the
              Offerings provided (iii) Your unuse of the Offerings (iv) in
              breach of this terms (v) force majeure Our decision on refunds and
              cancellations shall be final and binding. Your refund will be
              processed only when the conditions as may be stipulated by Us are
              fulfilled. All refunds initiated by Onescribe shall be refunded to
              the financial source account from which Users have initiated the
              transaction on the Platform. You acknowledge that after initiation
              of refund, it may take additional time for Your refund to reflect
              in Your account which is subject to Your financial institution or
              payment gateway service provider terms and conditions. In case the
              refund amount is not credited to your account in 9-15 working days
              from the date of refund, we kindly request you to send an email to
              support@onescribe.app along with the subscription details. We will
              provide you the necessary support in crediting back the refund
              amount.
            </p>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default RefundPolicy;
