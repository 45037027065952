import React, { useState, useEffect } from "react";
import "./navbar.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../assets/navbar/navlogo.svg";
import { useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import gplay from "../../assets/navbar/googleplay.png";
import appstore from "../../assets/navbar/appstore.svg";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function NavBar() {
  const { hash } = useLocation();
  const [isAndroid, setIsAndroid] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const notify = () => toast("App will be Available Soon!");

  // Scroll to the target section based on hash in the URL
  useEffect(() => {
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          setTimeout(() => {
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 100);
        }
      }, 100);
    }
  }, [hash]);

  // Add a scroll event listener to highlight the current section in the navbar
  useEffect(() => {
    function navHighlighter() {
      const sections = document.querySelectorAll("section[id]");
      let scrollY = window.scrollY; // Use window.scrollY instead of window.pageYOffset

      let homeLink = document.querySelector(".navbar-nav a[href*=homepage]");
      homeLink.classList.remove("active");

      sections.forEach((current) => {
        const sectionHeight = current.offsetHeight;
        const sectionTop = current.offsetTop - 70;
        let sectionId = current.getAttribute("id");

        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          if (sectionId === "header") {
            homeLink.classList.add("active");
          } else {
            const sectionLink = document.querySelector(
              `.navbar-nav a[href*=${sectionId}]`
            );
            if (sectionLink) {
              sectionLink.classList.add("active");
            }
          }
        } else {
          const sectionLink = document.querySelector(
            `.navbar-nav a[href*=${sectionId}]`
          );
          if (sectionLink) {
            sectionLink.classList.remove("active");
          }
        }
      });
    }

    window.addEventListener("scroll", navHighlighter);

    return () => {
      window.removeEventListener("scroll", navHighlighter);
    };
  }, []);

  // Detect Android or iOS and set the state accordingly
  useEffect(() => {
    setIsAndroid(/android/i.test(navigator.userAgent));
  }, []);

  // Handle the download button click based on the platform
  const handleDownloadClick = () => {
    if (isAndroid) {
      // Navigate to the Play Store for Android
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.unacademyapp";
    } else if (/iphone|ipad|ipod|macintosh/i.test(navigator.userAgent)) {
      // Navigate to the App Store for iOS
      window.location.href =
        "https://apps.apple.com/in/app/udemy-online-video-courses/id562413829";
    } else {
      // Show the modal if the device is not Android or iOS
      setShowModal(true);
    }
  };

  // Render the NavBar component
  return (
    
    <section id="homepage">
      <Navbar expand="lg" className="nav-sticky">
        <Container>
          <Navbar.Brand href="/#home">
            <img src={logo} alt="logo" className="Brand-logo" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setIsNavbarOpen(!isNavbarOpen)}
          />
          <Navbar.Collapse id="basic-navbar-nav" in={isNavbarOpen}>
            <Nav className="ms-auto navlinks">
              <Nav.Link
                href="/#homepage"
                className="nav-link me-3"
                onClick={() => setIsNavbarOpen(false)}
              >
                Home
              </Nav.Link>
              <Nav.Link
                href="/#about"
                className="nav-link me-3"
                onClick={() => setIsNavbarOpen(false)}
              >
                About Us
              </Nav.Link>
              <Nav.Link
                href="/#blog"
                className="nav-link me-3"
                onClick={() => setIsNavbarOpen(false)}
              >
                Blog
              </Nav.Link>
              <Nav.Link
                href="/#partner"
                className="nav-link me-3"
                onClick={() => setIsNavbarOpen(false)}
              >
                Partner with us
              </Nav.Link>
              <Nav.Link
                href="/#contact"
                className="nav-link me-3"
                onClick={() => setIsNavbarOpen(false)}
              >
                Contact Us
              </Nav.Link>

              {/* Navbar button for download */}
              <div className="nav-btn">
                <button
                  className="navbar-button"
                  // onClick={handleDownloadClick}
                  onClick={notify}
                >
                  Download Now
                  <ToastContainer />

                </button>
                
                {/* Modal for download options */}
                <Modal
                  centered
                  show={showModal}
                  onHide={() => setShowModal(false)}
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <div className="message-container">
                      App available on Android and iOS.
                    </div>
                    <div className="store-icon">
                      <img
                        src={gplay}
                        alt="Google Play"
                        className="googleplay"
                      />
                      <img
                        src={appstore}
                        alt="App Store"
                        className="appstore"
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="navbar-button"
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </section>
  );
}

export default NavBar;
