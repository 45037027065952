import React from "react";
import "./home.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../navbar/navbar";
import Banner from "../banner/banner";
import Footer from "../footer/footer";
import Disclaimer from "../footer/disclaimer";
import Partner from "../partner/partner";
import Blog from "../blog/blog";
import About from "../about/about";
import Contact from "../contact/contact";
import Faq from "../faq/faq";
import Customers from "../customers/customers";
import Business from "../business/business";

function Home() {
  return (
    <section id="home">
      <div>
        <NavBar />
        <Banner />
        <About />
        <Blog />
        <Customers />
        <Business />
        <Partner />
        <Faq />
        <Contact />
        <Disclaimer />
        <Footer />
      </div>
    </section>
  );
}

export default Home;
